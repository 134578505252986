.info {
  text-align: center;
  color: var(--cold);
  max-width: 320px;
  font-size: 16px;
}

.info a {
  font-size: inherit;
  text-decoration: none;
}


.info a:hover {
  text-decoration: underline;
  font-size: inherit;
}

.info.small {
  font-size: 11px;
}