a {
    font-size: 12px;
    color: var(--blue);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 400px;
    align-items: center;
}

.block {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
    gap:10px;
}

.borderbox {
    display: flex;
    padding: 3px;
    background: var(--base-hover-gradient);
    border-radius: 10px;
}

.label {
    display: flex;
    align-items: center;
    gap:10px
}

.message {
    background-color: #c90550;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    padding: 2px 10px;
}

input, textarea {
    flex:1;
    border: none;
    border-radius: 8px;
}

input:focus-visible, textarea:focus-visible {
    border: none !important;
    outline: none !important;
    outline-offset: none !important;
}

input {
    height: 30px;
}

textarea {
    height: 50px;
}

.required::after {
    content: '*';
    font-weight: bold;
    font-size: 20px;
    line-height: 10px;
    background-color:#fff;
    background-image: var(--base-gradient);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}