.progress {
  display: block;
  height: 8px;
  min-width: 300px;
  background-color: #eae9f1;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.bar {
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--base-gradient);
  transform: translateX(0%);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}
.bar.animate {
  animation: timer linear 30s 1 both;
  -webkit-animation: timer linear 30s 1 both;
}

@keyframes timer {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}
