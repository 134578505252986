.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
}

.message {
  font-size: 14px;
}

.item {
  display: flex;
  gap: 5px;
  flex-direction: column;
  background: var(--light-cold-10);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.title {
  display: flex;
  align-items: center;
  padding: 3px 3px 3px 20px;
  gap: 20px;
  flex: 1;
  background: var(--light-cold-10);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.title h3 {
  flex: 1;
  font-weight: normal;
  font-size: 16px;
  color: var(--dark-warm);
}

.description {
  padding: 3px 20px 10px;
  max-width: 440px;
  font-size: 12px;
  color: var(--cold);
}
