.connectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.body {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  align-items: center;
}

.qrcode {
  width: 100%;
  display: flex;
  justify-content: center;
}

.qrcode svg {
  fill: var(--blue);
}
