.nodebox {
    display: flex;
    flex:1;
    flex-direction: column;
    gap: 5px;
}

.node {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.node span {
    flex: 1;
}

.node span a {
    font-size: 15px;
    color: var();
    text-decoration: none;
}

.nodeinfo {
    flex: 1;
    background-color: var(--light-cold-10);
    border-radius: 0 0 10px 10px;
}