.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--base-gradient);
  border: none;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  transition: all ease-out 200ms;
}

.button.small {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.text {
  flex: none;
  color: #fff;
}

@media (hover: hover) {
  .button:hover {
    background: var(--base-hover-gradient);
  }
}

@media (hover: none) {
  .button:active {
    background: var(--base-hover-gradient);
  }
}
