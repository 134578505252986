.box {
  background: var(--dark-cold);
  border: 2px solid var(--purple);
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 777;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.3);
  animation-name: open;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.open {
  animation-name: open;
}
.hide {
  animation-name: hide;
}

.title {
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
  background-image: var(--base-gradient);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.text {
  color: var(--light-cold);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes open {
  0% {
    opacity: 0;
    transform: translate(-50%, 30vh);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 30vh);
  }
}