@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("./reset.css");

html,
body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  height: 100%;
}
