.page {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  background-color: #fff;
  background-image: var(--base-gradient);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.chain {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.color {
  background-color: #fff;
  background-image: var(--base-gradient);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.err {
  display: flex;
  justify-content: center;
  color: var(--err-color);
}
