:root {
  --base-gradient: radial-gradient(
    circle farthest-side at 100% 0%,
    #ab00d6,
    #5585ff
  );
  --base-hover-gradient: radial-gradient(
    circle farthest-side at 100% 0%,
    #ab00d6 20%,
    #5585ff
  );
  --base-gradient-moz: -moz-radial-gradient(
    circle farthest-side at 100% 0%,
    #ab00d6,
    #5585ff
  );
  --base-gradient-webkit: -webkit-radial-gradient(
    circle farthest-side at 100% 0%,
    #ab00d6,
    #5585ff
  );
  --purple: #ab00d6;
  --blue: #5585ff;
  --light-cold: #b1aec8;
  --light-cold-20: rgba(177, 174, 200, 0.2);
  --light-cold-10: rgba(177, 174, 200, 0.1);
  --cold: #514f61;
  --dark-cold: #222126;
  --light-warm: #e1d1e5;
  --warm: #675e69;
  --dark-warm: #2a262b;

  --err-color: #c90043;
}
